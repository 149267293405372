<template>
    <div>
        <v-btn small color="blue-grey" class="white--text" style="width: 100%" :href="`http://${ip}`" target="_blank" v-if="ip && isAdmin">
            <v-icon left>vpn_lock</v-icon>
            VPN: {{ ip }}
        </v-btn>
        <strong v-else-if="IP">VPN: {{ ip }}</strong>
        <strong v-else>Sem acesso VPN</strong>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        IP: {
            type: String,
            default: undefined,
        },
        UUID: {
            type: String,
            default: undefined,
        },
        server: {
            type: String,
            default: undefined,
        },
    },

    data() {
        return {
            isAdmin: false,
            ip: undefined,
        };
    },

    mounted() {
        this.ip = this.IP;
        this.isAdmin = this.verifyRole("ROLE_ADMIN");

        if (this.IP == undefined && this.UUID != undefined) {
            this.$http.getDynamic(this.server, `api/v2/vpn/${this.UUID}`).then((result) => {
                if (result && result.ip)
                    this.ip = result.ip;
            });
        }

    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },

    methods: {
    },
};
</script>